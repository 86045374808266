main {
  max-width: 1280px;
  margin: 0 auto;
}

.App {
  text-align: left;
  /* background: url(/public/bp.webp) no-repeat center fixed; */
  background-blend-mode: color;
  background-color: rgba(255, 255, 255, 0.85);
  background-size: contain;
  width: 94%;
  margin: 0 auto;
  padding: 2%;
}

.App-logo {
  height: 100px;
  pointer-events: none;
  margin-bottom: 40px;
}

.bu {
  font-size: 1.2em;
  font-weight: bold;
  margin-bottom: 15px;
}

.b {
  font-weight: bold;
}

.App-header {
  background-color: black;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}


#sucursales {
  display: flex;
  margin-top: 30px;
}

.suc-info {
  background-color: rgba(255, 255, 255, 0.8);
  margin: 0 1%;
  width: 32%;
  float: left;
  border: #efefef 1px solid;
  border-radius: 5px;
}

.suc-info li {
  list-style-type: none;
  margin-bottom: 5px;
  margin-right: 1%;
}

#nosotros ul {
  display: inline-block;
}

#nosotros li {
  width: 50%;
  float: left;
}

@media screen and (max-width: 600px) {
  #sucursales {
    display: block;
  }

  .suc-info ul {
    padding: 0;
  }

  .suc-info li a {
    text-decoration: none;
  }

  .suc-info {
    margin-bottom: 10px;
    width: 90%;
    padding: 2%;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}