body {
  margin: 0;
}

#root {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'Modernist';
  src: url(/public/fonts/Sk-Modernist-Regular.otf) format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Modernist';
  src: url(/public/fonts/Sk-Modernist-Bold.otf) format('opentype');
  font-weight: bold;
  font-style: bold;
}


@font-face {
  font-family: "SF Display";
  src: url(/public/fonts/SFPRODISPLAYREGULAR.OTF) format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "SF Display";
  src: url(/public/fonts/SFPRODISPLAYBLACKITALIC.OTF) format('opentype');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: "SF Display";
  src: url(/public/fonts/SFPRODISPLAYBOLD.OTF) format('opentype');
  font-weight: bold;
  font-style: bold;
}

@font-face {
  font-family: "SF Display";
  src: url(/public/fonts/SFPRODISPLAYHEAVYITALIC.OTF) format('opentype');
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: "SF Display";
  src: url(/public/fonts/SFPRODISPLAYLIGHTITALIC.OTF) format('opentype');
  font-weight: light;
  font-style: italic;
}

@font-face {
  font-family: "SF Display";
  src: url(/public/fonts/SFPRODISPLAYMEDIUM.OTF) format('opentype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "SF Display";
  src: url(/public/fonts/SFPRODISPLAYSEMIBOLDITALIC.OTF) format('opentype');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: "SF Display";
  src: url(/public/fonts/SFPRODISPLAYTHINITALIC.OTF) format('opentype');
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: "SF Display";
  src: url(/public/fonts/SFPRODISPLAYULTRALIGHTITALIC.OTF) format('opentype');
  font-weight: 100;
  font-style: italic;
}